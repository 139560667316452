<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'events' }"
          >Events</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="form">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ displayName }}
      </div>

      <div class="d-flex my-6">
        <v-tabs hide-slider class="user-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-md-4 col-sm-12 col-xs-12 mt-6 pa-0">
            <v-form ref="form" v-if="form" class="user-form">
              <v-divider></v-divider>

              <h3 class="py-5 primary--text">Event Information</h3>

              <label class="text-field-label">Name</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                v-model="form.name"
                :error-messages="form.$getError('name')"
                :loading="isLoading"
              ></v-text-field>

              <div class="mt-2">
                <v-btn
                  color="error"
                  class="mr-4 px-6"
                  height="40px"
                  width="100%"
                  @click="deleteDialog = true"
                  >Delete</v-btn
                >
              </div>
              <div class="mt-4">
                <v-btn
                  color="primary"
                  class="mr-4 px-6"
                  height="40px"
                  width="100%"
                  @click="updateDetail"
                  :loading="form.$busy"
                  >Update</v-btn
                >
              </div>
            </v-form>
          </v-tab-item>

          <v-tab-item>
            <UploadPhotos
              :event="event"
              :photos="photos"
              @error="uploadPhotosError"
              @deleted="photoDeleted"
              @success="uploadPhotosSuccess"
            />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
    <ConfirmModal
      v-model="deleteDialog"
      title="Delete Event"
      :message="`Are you sure you want to delete this event ?`"
      submit="Yes, Delete please"
      @cancel="deleteDialog = false"
      @confirm="deleteEvent"
    ></ConfirmModal>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ConfirmModal from '@/components/modals/ConfirmModal'
import UploadPhotos from './components/UploadPhotos'

import Form from '@/utils/form'
import { mapState } from 'vuex'

export default {
  name: 'EventDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
    UploadPhotos
  },

  computed: {
    ...mapState({
      event: state => state.event.eventDetails,
      photos: state => state.event.photos
    }),
    displayName() {
      return this.form.name
    }
  },

  created() {
    this.getEventDetail()
  },

  watch: {
    $route: {
      handler() {
        this.getEventDetail()
        this.getEventPhotos()
      },
      immediate: true
    }
  },

  destroyed() {
    this.$store.commit('event/clearEventList')
  },

  data() {
    return {
      isLoading: false,
      form: null,
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline
      },
      tabItems: [{ tab: 'Info' }, { tab: 'Photos' }],
      deleteDialog: false,
      isLoadingDelete: false,
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    async getEventDetail() {
      this.isLoading = true
      await this.$store
        .dispatch('event/getEventDetail', this.$route.params.id)
        .then(() => {
          this.form = new Form(this.event)
          this.isLoading = false
        })
    },

    async updateDetail() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.$store
        .dispatch('event/updateEvent', this.form.$data())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Event details successfully updated!', 'success')
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    deleteEvent() {
      this.isLoadingDelete = true
      this.event.delete().then(() => {
        this.isLoadingDelete = false
        this.deleteDialog = false
        this.showSnackbar('Event deleted successfully!')
        this.$router.replace('/events')
      })
    },

    async getEventPhotos() {
      const eventId = this.$route.params.id
      try {
        await this.$store.dispatch('event/getEventPhotos', eventId)
      } catch (error) {
        this.showSnackbar(error.response.data.message, 'error')
      }
    },

    photoDeleted() {
      this.showSnackbar('Photo deleted successfully!')
      this.getEventPhotos()
    },

    uploadPhotosSuccess() {
      this.showSnackbar('Photos uploaded successfully!')
    },

    uploadPhotosError(error) {
      this.showSnackbar(error.message, 'error')
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>
