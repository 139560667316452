<template>
  <div>
    <v-form ref="form" class="event-form">
      <label class="text-field-label">Name</label>
      <v-text-field
        v-model="eventForm.name"
        flat
        solo
        required
        class="mt-2"
        :error-messages="eventForm.$getError('name')"
        @input="eventForm.$clearError('name')"
      ></v-text-field>

      <label class="text-field-label">Attach Photos</label>
      <add-photos @images="getPhotos" ref="photos">
        <template #photo-error>
          <div
            class="custom__message error--text mt-3"
            v-if="eventForm.$hasError('photos')"
          >
            {{ customError(eventForm.$hasError('photos')) }}
          </div>
        </template>
      </add-photos>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AddPhotos from './AddPhotos.vue'

export default {
  components: {
    AddPhotos
  },

  data() {
    return {
      isError: true
    }
  },

  computed: {
    ...mapState('event', ['eventForm'])
  },

  methods: {
    getPhotos(photos) {
      this.eventForm.$clearError('photos')
      this.$store.commit('event/setPhotoFormData', photos)
    },

    customError(isError) {
      if (isError) {
        return this.eventForm.$getError('photos')[0]
      }
    },

    reset() {
      this.$refs.photos.reset()
    }
  }
}
</script>

<style lang="scss">
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-left: 0px;
}
.error--text {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  font-size: 12px;
}
</style>
