<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Bookings ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 200px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search boooking ID or customer name"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <template>
        <v-select
          class="ml-5"
          hide-details
          dense
          item-text="label"
          item-value="value"
          v-model="filter.status"
          :items="[
            { value: '', label: 'All' },
            { value: 'pending', label: 'Pending' },
            { value: 'approved', label: 'Approved' },
            { value: 'rejected', label: 'Rejected' },
            { value: 'paid', label: 'Paid' }
          ]"
          style="max-width: 120px"
          @change="filterProviders"
        />

        <v-select
          class="ml-2"
          hide-details
          dense
          item-text="label"
          item-value="value"
          v-model="defaultFilter"
          :items="filterBy"
          style="max-width: 125px"
          @change="filterProviders"
        />

        <v-btn
          depressed
          class="ml-2"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterProviders()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterProviders()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>

        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="provider-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="20%" class="text-left pa-0">ID</th>
            <th width="20%" class="text-left">Customer</th>
            <th width="20%" class="text-left">Event</th>
            <th width="15%" class="text-left">Provider</th>
            <th width="10%" class="text-left">Status</th>
            <th width="15%" class="text-left">Date Added</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="booking in bookings"
            :key="booking.id"
            class="clickable"
            @click="
              $router.push({
                name: 'bookings.details',
                params: { id: booking.booking_id }
              })
            "
          >
            <td>
              <v-badge
                v-if="booking.is_new"
                color="green"
                message="new"
                content="new"
                top
              >
                {{ booking.booking_id }}
              </v-badge>
              <span v-else>{{ booking.booking_id }}</span>
            </td>
            <td>{{ booking.booking_details.full_name }}</td>
            <td>{{ booking.listing.event.name }}</td>
            <td>{{ booking.listing.provider.name }}</td>
            <td class="text-capitalize">{{ booking.status }}</td>
            <td>{{ booking.addedDate }}</td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(bookings.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown
} from '@mdi/js'

import ControlsMixin from '@/utils/mixins/Controls'
import infiniteScroll from 'vue-infinite-scroll'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import debounce from 'lodash/debounce'

export default {
  name: 'ProvidersPage',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon
  },
  directives: {
    infiniteScroll
  },
  data() {
    return {
      loading: false,
      showSearch: false,
      filter: {
        search: '',
        status: ''
      },
      showForm: false,
      sortOrder: false,
      defaultFilter: 'created_at',
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown
      },
      filterBy: [
        {
          value: 'created_at',
          label: 'Added date'
        }
      ],
      showDeleteModal: false
    }
  },
  computed: {
    ...mapState({
      bookings: state => state.booking.list,
      listMeta: state => state.booking.listMeta
    }),
    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    }
  },
  created() {
    this.clearBookings()
    this.fetchBookings(1)
  },
  methods: {
    ...mapActions({
      getBookings: 'booking/getBookings'
    }),
    ...mapMutations({
      clearBookings: 'booking/clearBookingList'
    }),
    async fetchBookings(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      if (this.filter.status) {
        params.status = this.filter.status
      }

      this.loading = true
      await this.getBookings(params)
      this.loading = false
    },
    search: debounce(function() {
      this.clearBookings()
      this.fetchBookings()
    }, 600),

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchBookings(this.listMeta.current_page + 1)
      }
    },
    filterProviders(filterValue) {
      this.clearBookings()
      this.fetchBookings(null, filterValue)
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    }
  }
}
</script>
