<template>
  <div v-if="!isLoading">
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-left">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'bookings' }"
          >Bookings</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="d-flex justify-space-between">
      <div class="display-1 font-weight-bold my-1 text-capitalize">
        Booking # {{ booking.booking_id }}
      </div>

      <div v-if="booking.status === 'pending'">
        <v-btn
          class="info mr-1"
          elevation="2"
          :loading="updating"
          @click="approve"
        >
          Approved
        </v-btn>

        <v-btn class="error" elevation="2" :loading="updating" @click="reject">
          Reject
        </v-btn>
      </div>
    </div>

    <div class="my-8">
      <div class="font-weight-bold text-capitalize">
        <p class="mb-0">Event: {{ booking.listing.event.name }}</p>
        <p>Status: {{ booking.status }}</p>
      </div>

      <v-row class="mb-5">
        <v-col cols="6">
          <p class="font-weight-bold text-capitalize">Customer Details</p>
          <p class="mb-2">Name: {{ booking.booking_details.full_name }}</p>
          <p class="mb-2">Email: {{ booking.booking_details.email }}</p>
          <p class="mb-2">Where: {{ booking.booking_details.where }}</p>
          <p class="mb-2">When: {{ booking.booking_details.when }}</p>
          <p class="mb-2">
            Event Info: {{ booking.booking_details.description }}
          </p>
        </v-col>
        <v-col cols="6">
          <p class="font-weight-bold text-capitalize">Provider Details</p>
          <p class="mb-2">Name: {{ booking.listing.provider.name }}</p>
          <p class="mb-2">Email: {{ booking.listing.provider.email }}</p>
          <p class="mb-2">
            Phone Number: {{ booking.listing.provider.phone_number }}
          </p>
          <p class="mb-2">Address: {{ booking.listing.provider.address }}</p>
        </v-col>
      </v-row>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 1rem" width="20%" class="text-left">
              Service
            </th>
            <th style="font-size: 1rem" width="20%" class="text-left">
              Pricing
            </th>
            <th style="font-size: 1rem" width="20%" class="text-left">
              Quantity
            </th>
            <th style="font-size: 1rem" width="15%" class="text-left">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="service in booking.service_include" :key="service.id">
            <td style="font-size: 1rem">{{ service.name }}</td>
            <td style="font-size: 1rem">
              {{ pricingDisplay(service.pricing, service.amount) }}
            </td>
            <td style="font-size: 1rem">{{ service.quantity }}</td>
            <td style="font-size: 1rem">$ {{ service.item_sub_total }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="font-weight-bold">TAX</td>
            <td class="font-weight-bold">$ {{ booking.gst_amount }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="font-weight-bold">ADMIN FEE</td>
            <td class="font-weight-bold">
              $ {{ booking.admin_fee_per_checkout }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="font-weight-bold">TOTAL</td>
            <td class="font-weight-bold">$ {{ booking.total }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import Api from '@/services/api'
import { mdiChevronLeft } from '@mdi/js'
import { mapState } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
export default {
  name: 'ProviderDetails',
  components: {
    AppBarNavIcon
  },
  data() {
    return {
      isLoading: true,
      updating: false,
      tabs: [
        { name: 'Info', to: '' },
        { name: 'Photos', to: 'photos' },
        { name: 'Services', to: 'services' },
        { name: 'Listings', to: 'listings' }
      ],
      icons: {
        arrowLeft: mdiChevronLeft
      }
    }
  },
  computed: {
    ...mapState({
      booking: state => state.booking.details
    })
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    pricingDisplay(type, amount) {
      if (type === 'fixed') return `$ ${amount}`
      if (type === 'per_hour') {
        return `$${amount}/hr`
      }
      if (type === 'per_quantity') {
        return `$${amount}/qty`
      }
    },
    async getDetails() {
      this.isLoading = true

      await this.$store
        .dispatch('booking/getBookingDetails', this.$route.params.id)
        .then(() => {
          this.isLoading = false
        })
    },
    approve() {
      this.updating = true

      Api.post(`admin/bookings/${this.$route.params.id}/approve`)
        .then(response => {
          this.$store.commit('booking/setBookingDetails', response.data.data)
        })
        .finally(() => (this.updating = false))
    },
    reject() {
      this.updating = true

      Api.post(`admin/bookings/${this.$route.params.id}/reject`)
        .then(response => {
          this.$store.commit('booking/setBookingDetails', response.data.data)
        })
        .finally(() => (this.updating = false))
    }
  }
}
</script>
