<template>
  <div class="upload-photos">
    <v-btn
      class="primary mt-5"
      elevation="2"
      small
      @click="$refs.filePicker.click()"
      :loading="isUploading"
    >
      <span>
        <v-icon>{{ icons.plus }}</v-icon> Add Photos
      </span>
    </v-btn>

    <input
      ref="filePicker"
      class="d-none"
      type="file"
      multiple
      accept="image/*"
      @change="onFileChange"
    />

    <v-row class="mt-4">
      <v-col
        v-for="(image, i) in imageData"
        :key="i"
        class="d-flex child-flex"
        cols="4"
      >
        <div class="upload-photos__image">
          <v-btn
            v-if="image.id"
            class="ma-2 upload-photos__close"
            fab
            small
            @click="confirmDeleteImage(image)"
          >
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
          <v-img
            :src="image.url"
            aspect-ratio="1"
            class="grey lighten-2"
            :class="{ 'image-uploading': !image.id }"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </v-col>
    </v-row>

    <ConfirmModal
      v-model="showDeleteModal"
      title="Delete Photo"
      message="Are you sure you want to delete this photo?"
      @cancel="showDeleteModal = false"
      @confirm="deleteImage"
    />
  </div>
</template>
<script>
import { mdiPlus, mdiClose } from '@mdi/js'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { each, find } from 'lodash'

export default {
  props: {
    event: {
      type: Object,
      default: () => {}
    },
    photos: {
      type: Array,
      default: () => []
    }
  },

  components: {
    ConfirmModal
  },

  data() {
    return {
      icons: {
        plus: mdiPlus,
        close: mdiClose
      },
      selectedImages: [],
      imageData: [],
      isBusy: false,
      showDeleteModal: false,
      imageToDelete: null,
      imageUploadMeta: {
        maxPhotos: 5,
        maxFileSize: 5242880,
        totalCount: null,
        successCount: null
      },
      uploadText: 'Uploading Photos...',
      isUploading: false
    }
  },
  created() {
    if (this.photos) {
      this.imageData = this.photos.map(item => {
        return {
          url: item.url,
          id: item.id
        }
      })
    }
  },

  methods: {
    async onFileChange(value) {
      const files = [...value.target.files]

      // check if there are files
      if (!files.length) {
        return
      }

      this.isUploading = true

      for (const file of files) {
        // validate file type
        if (!file.type.match('image.*')) {
          this.$emit('error', {
            message: `${file.name} is not a valid image file`
          })
          return
        }
      }

      const formData = new FormData()

      files.map(photo => formData.append('photos[]', photo, photo.name))

      this.$store
        .dispatch('event/uploadEventPhotos', {
          formData,
          event_id: this.event.id
        })
        .then(result => {
          each(result, photo => {
            const exist = find(this.imageData, { id: photo.id })
            if (!exist) {
              this.imageData.push({
                url: photo.url,
                id: photo.id
              })
            }
          })
          this.$emit('success')
          this.isUploading = false
        })
    },

    confirmDeleteImage(image) {
      this.showDeleteModal = true
      this.imageToDelete = image
    },

    async deleteImage() {
      if (this.imageToDelete) {
        const eventId = this.$route.params.id

        try {
          await this.$store.dispatch('event/deleteEventPhoto', {
            event_id: eventId,
            photo_id: this.imageToDelete.id
          })

          this.showDeleteModal = false

          const index = this.imageData.findIndex(
            image => image.id === this.imageToDelete.id
          )
          this.imageData.splice(index, 1)

          this.$emit('deleted')
        } catch (error) {
          this.$emit('error', {
            message: error.response.data.message
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-photos {
  max-width: 800px;

  &__image {
    position: relative;
  }

  &__close {
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 5;
  }
  .image-uploading {
    opacity: 0.5;
  }
}
</style>
