<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="isLoading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Service Type ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <filters
        :filter="filter"
        @search="search"
        @filter-event="filterEvent"
      ></filters>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="event-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="isLoading"
      infinite-scroll-distance="100"
    >
      <v-btn class="primary" elevation="2" small @click="openDialog = true">
        <span>
          <v-icon small>{{ icons.plus }}</v-icon>
          Add Service Type
        </span>
      </v-btn>

      <service-type-list
        :is-loading="isLoading"
        :service-types="serviceTypes"
        @open-dialog="openDialog = true"
        @delete="deleteDialog = true"
      ></service-type-list>
    </div>

    <form-modal
      v-model="openDialog"
      :title="`${form.id ? 'Update Service Type' : 'Add Service Type'}`"
      :max-width="600"
    >
      <template #form>
        <service-type-form></service-type-form>
      </template>

      <template #submit-action>
        <v-btn
          text
          @click="$store.commit('serviceType/resetForm'), (openDialog = false)"
          >Cancel</v-btn
        >
        <v-btn
          text
          :loading="form.$busy"
          v-if="form.id"
          @click="updateServiceType"
          >Submit</v-btn
        >
        <v-btn v-else text :loading="form.$busy" @click="addServiceType"
          >Submit</v-btn
        >
      </template>
    </form-modal>

    <ConfirmModal
      v-model="deleteDialog"
      title="Delete Service Type"
      :message="`Are you sure you want to delete this service type ?`"
      submit="Yes, Delete please"
      @cancel="$store.commit('serviceType/resetForm'), (deleteDialog = false)"
      @confirm="deleteServiceType"
    ></ConfirmModal>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiPlus, mdiPencilBoxOutline } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import infiniteScroll from 'vue-infinite-scroll'
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Filters from './components/Filters.vue'
import ServiceTypeList from './components/ServiceTypeList.vue'
import FormModal from '@/components/modals/FormModal.vue'
import ServiceTypeForm from './components/ServiceTypeForm.vue'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ServiceType',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll
  },

  components: {
    AppBarNavIcon,
    Filters,
    ServiceTypeList,
    FormModal,
    ServiceTypeForm,
    ConfirmModal
  },

  data() {
    return {
      deleteDialog: false,
      openDialog: false,
      isLoading: false,
      filter: {
        search: ''
      },
      snackbar: {
        show: false,
        message: null,
        color: null
      },
      sortOrder: 'name',
      icons: {
        plus: mdiPlus,
        edit: mdiPencilBoxOutline
      },
      isSubmit: false
    }
  },

  computed: {
    ...mapState({
      serviceTypes: state => state.serviceType.list,
      listMeta: state => state.serviceType.listMeta
    }),
    ...mapState('serviceType', ['isSuccess', 'form'])
  },

  created() {
    this.$store.commit('serviceType/clearServiceTypeList')
    this.fetchServiceTypes(1)
  },

  methods: {
    async fetchServiceTypes(page = 1) {
      if (this.isLoading) return

      let params = {
        page,
        sort: this.sortOrder
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.isLoading = true
      this.$store.dispatch('serviceType/getServiceTypes', params).then(() => {
        this.isLoading = false
      })
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchServiceTypes(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function() {
      this.$store.commit('serviceType/clearServiceTypeList')
      this.fetchServiceTypes()
    }, 600),

    filterEvent(filterValue) {
      this.sortOrder = filterValue
      this.$store.commit('serviceType/clearServiceTypeList')
      this.fetchServiceTypes(null, filterValue)
    },

    async addServiceType() {
      try {
        this.form.$busy = true

        await this.$store.dispatch(
          'serviceType/addServiceType',
          this.form.$data()
        )
        this.showSnackbar('Service type successfully added!', 'success')
        this.fetchServiceTypes()
        this.openDialog = false
      } catch (error) {
        this.form.$busy = false
        if (error.response.status === 422) {
          this.form.$setErrors(error.response.data.errors)
        }
      }
    },

    async updateServiceType() {
      try {
        this.form.$busy = true

        await this.$store.dispatch(
          'serviceType/updateServiceType',
          this.form.$data()
        )
        this.showSnackbar(
          'Service type detail successfully updated!',
          'success'
        )
        this.fetchServiceTypes()
        this.openDialog = false
      } catch (error) {
        this.form.$busy = false
        if (error.response.status === 422) {
          this.form.$setErrors(error.response.data.errors)
        }
      }
    },

    async deleteServiceType() {
      try {
        await this.$store.dispatch(
          'serviceType/deleteServiceType',
          this.form.$data()
        )
        this.fetchServiceTypes()
        this.deleteDialog = false
      } catch (error) {
        this.showSnackbar(
          'Something went wrong please reload the page!',
          'error'
        )
      }
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>

<style></style>
