<template>
  <div>
    <v-form ref="form" class="provider-form">
      <logo-form @change="onUploadImage" ref="logo" />

      <label class="text-field-label">Name</label>
      <v-text-field
        v-model="data.name"
        flat
        solo
        required
        class="mt-1"
        :error-messages="data.$getError('name')"
        @input="data.$clearError('name')"
      />

      <label class="text-field-label">Email</label>
      <v-text-field
        v-model="data.email"
        flat
        solo
        required
        class="mt-1"
        :error-messages="data.$getError('email')"
        @input="data.$clearError('email')"
      />

      <label class="text-field-label">Address</label>
      <v-autocomplete
        v-model="data.address"
        flat
        no-filter
        :items="locations"
        :error-messages="data.$getError('address')"
        item-value="description"
        item-text="description"
        @keyup="getLocations"
        :loading="loadingLocations"
        @input="data.$clearError('address')"
      />

      <label class="text-field-label">Phone Number</label>
      <v-text-field
        v-model="data.phone_number"
        type="number"
        flat
        solo
        required
        class="mt-1"
        :error-messages="data.$getError('phone_number')"
        @input="data.$clearError('phone_number')"
      />

      <label class="text-field-label">Description</label>
      <v-textarea
        v-model="data.description"
        flat
        solo
        required
        class="mt-1"
        rows="3"
        :error-messages="data.$getError('description')"
        @input="data.$clearError('description')"
      />
    </v-form>
  </div>
</template>

<script>
import LogoForm from './LogoForm.vue'
import Api from '@/services/api'

export default {
  props: {
    data: {
      required: true
    }
  },
  components: {
    LogoForm
  },
  data() {
    return {
      loadingLocations: false,
      locations: []
    }
  },
  methods: {
    onUploadImage(blob) {
      this.data.logo = blob
    },
    reset() {
      this.data.$reset()
      this.$refs.logo.reset()
    },
    getLocations(e) {
      this.loadingLocations = true
      Api.get('/places', { params: { input: e.target.value.trim() } })
        .then(response => {
          this.locations = response.data.data
        })
        .finally(() => (this.loadingLocations = false))
    }
  }
}
</script>
