<template>
  <div v-if="!isLoading">
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-left">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'providers' }"
          >Providers</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="display-2 font-weight-bold my-1 text-capitalize">
      {{ provider.name }}
    </div>

    <v-tabs>
      <v-tab
        v-for="tab in tabs"
        :key="tab.name"
        :to="`/providers/${provider.id}/${tab.to}`"
        >{{ tab.name }}</v-tab
      >
    </v-tabs>

    <div class="mt-8">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { mapState } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
export default {
  name: 'ProviderDetails',
  components: {
    AppBarNavIcon
  },
  data() {
    return {
      isLoading: true,
      tabs: [
        { name: 'Info', to: '' },
        { name: 'Photos', to: 'photos' },
        { name: 'Services', to: 'services' },
        { name: 'Listings', to: 'listings' }
      ],
      icons: {
        arrowLeft: mdiChevronLeft
      }
    }
  },
  computed: {
    ...mapState({
      provider: state => state.provider.details
    })
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    async getDetails() {
      this.isLoading = true

      await this.$store
        .dispatch('provider/getProviderDetails', this.$route.params.id)
        .then(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
