<template>
  <div>
    <v-form ref="form" class="event-form">
      <v-text-field
        v-model="form.id"
        flat
        solo
        required
        class="mt-2 d-none"
      ></v-text-field>

      <label class="text-field-label">Name</label>
      <v-text-field
        v-model="form.name"
        flat
        solo
        required
        class="mt-2"
        :error-messages="form.$getError('name')"
        @input="form.$clearError('name')"
      ></v-text-field>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ServiceTypeForm',

  data() {
    return {
      isError: true
    }
  },

  computed: {
    ...mapState('serviceType', ['form'])
  }
}
</script>

<style lang="scss">
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-left: 0px;
}
.error--text {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  font-size: 12px;
}
</style>
