<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="isLoading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Events ({{ listMeta.total }})</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <filters
        :filter="filter"
        @search="search"
        @filter-event="filterEvent"
      ></filters>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="event-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="isLoading"
      infinite-scroll-distance="100"
    >
      <v-btn class="primary" elevation="2" small @click="openDialog = true">
        <span>
          <v-icon>{{ icons.plus }}</v-icon>
          Add Event
        </span>
      </v-btn>
      <event-list :is-loading="isLoading" :events="events"></event-list>
    </div>

    <form-modal v-model="openDialog" title="Add Event" :max-width="600">
      <template #form>
        <event-form ref="form"></event-form>
      </template>
      <template #submit-action>
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn text :loading="eventForm.$busy" @click="addEvent">Submit</v-btn>
      </template>
    </form-modal>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiArrowUp,
  mdiArrowDown,
  mdiPlus,
  mdiPencilBoxOutline
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import infiniteScroll from 'vue-infinite-scroll'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'
import FormModal from '@/components/modals/FormModal.vue'
import EventForm from './components/EventForm.vue'
import EventList from './components/EventList.vue'
import Filters from './components/Filters.vue'

export default {
  name: 'EventPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll
  },

  components: {
    AppBarNavIcon,
    FormModal,
    EventForm,
    EventList,
    Filters
  },

  data() {
    return {
      openDialog: false,
      isLoading: false,
      filter: {
        search: ''
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        edit: mdiPencilBoxOutline,
        plus: mdiPlus
      },
      snackbar: {
        show: false,
        message: null,
        color: null
      },
      sortOrder: 'name',
      isSubmit: false
    }
  },

  created() {
    this.$store.commit('event/clearEventList')
    this.fetchEvents(1)
  },

  computed: {
    ...mapState({
      events: state => state.event.list,
      listMeta: state => state.event.listMeta
    }),
    ...mapState('event', ['photoFormData', 'eventForm', 'isSuccess'])
  },

  methods: {
    async fetchEvents(page = 1) {
      if (this.isLoading) return

      let params = {
        page,
        sort: this.sortOrder
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.isLoading = true
      this.$store.dispatch('event/getEvents', params).then(() => {
        this.isLoading = false
      })
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchEvents(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function() {
      this.$store.commit('event/clearEventList')
      this.fetchEvents()
    }, 600),

    filterEvent(filterValue) {
      this.sortOrder = filterValue
      this.$store.commit('event/clearEventList')
      this.fetchEvents(null, filterValue)
    },

    addEvent() {
      this.eventForm.$busy = true

      const formData = new FormData()

      if (this.photoFormData) {
        this.photoFormData.map(photo =>
          formData.append('photos[]', photo.file, photo.file.name)
        )
      }

      formData.append('name', this.eventForm.name)

      this.$store.dispatch('event/addEvent', { formData }).then(() => {
        if (this.isSuccess) {
          this.showSnackbar('Event added successfully!')
          this.fetchEvents()
          this.openDialog = false
          this.eventForm.$busy = false
          this.$refs.form.reset()
        } else {
          this.eventForm.$busy = false
        }
      })
    },

    cancel() {
      this.$store.commit('event/resetForm')
      this.$refs.form.reset()
      this.openDialog = false
    },

    // setSortOrder(value) {
    //   console.log(value)
    //   this.sortOrder = value
    // },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>

<style></style>
