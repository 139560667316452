<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Date Added</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="event in events"
          :key="event.id"
          class="clickable"
          @click="
            $router.push({ name: 'event.details', params: { id: event.id } })
          "
        >
          <td>{{ event.name }}</td>
          <td>{{ event.addedDate }}</td>
          <td>
            <v-icon title="Edit">{{ icons.edit }}</v-icon>
          </td>
        </tr>
        <template v-if="isLoading">
          <tr v-for="index in 10" :key="index + '-skeleton'">
            <td class="py-3 pl-0" width="5%">
              <v-skeleton-loader
                class="avatar-loading--36"
                type="avatar"
                transition="fade-transition"
              />
            </td>
            <td class="pl-0">
              <v-skeleton-loader
                type="list-item"
                transition="fade-transition"
              />
            </td>
            <td class="pl-0">
              <v-skeleton-loader
                type="list-item"
                transition="fade-transition"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
    <div
      class="d-flex justify-center mt-3"
      v-if="!(events.length > 0) && !isLoading"
    >
      <p>No match found.</p>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiPencilBoxOutline } from '@mdi/js'
export default {
  name: 'EventList',

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    events: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      icons: {
        edit: mdiPencilBoxOutline,
        plus: mdiPlus
      }
    }
  }
}
</script>

<style></style>
