<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Date Added</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="serviceType in serviceTypes" :key="serviceType.id">
          <td>{{ serviceType.name }}</td>
          <td>{{ serviceType.addedDate }}</td>
          <td>
            <v-icon title="Edit" @click="editServiceType(serviceType)">{{
              icons.edit
            }}</v-icon>
            <v-icon title="Delete" @click="deleteServiceType(serviceType)">{{
              icons.delete
            }}</v-icon>
          </td>
        </tr>
        <template v-if="isLoading">
          <tr v-for="index in 10" :key="index + '-skeleton'">
            <td class="py-3 pl-0" width="5%">
              <v-skeleton-loader
                class="avatar-loading--36"
                type="avatar"
                transition="fade-transition"
              />
            </td>
            <td class="pl-0">
              <v-skeleton-loader
                type="list-item"
                transition="fade-transition"
              />
            </td>
            <td class="pl-0">
              <v-skeleton-loader
                type="list-item"
                transition="fade-transition"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
    <div
      class="d-flex justify-center mt-3"
      v-if="!(serviceTypes.length > 0) && !isLoading"
    >
      <p>No match found.</p>
    </div>
  </div>
</template>

<script>
import { mdiPlus, mdiPencilBoxOutline, mdiTrashCan } from '@mdi/js'
export default {
  name: 'EventList',

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    serviceTypes: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      icons: {
        edit: mdiPencilBoxOutline,
        plus: mdiPlus,
        delete: mdiTrashCan
      }
    }
  },

  methods: {
    editServiceType(data) {
      this.$store.commit('serviceType/setFormData', data)
      this.$emit('open-dialog')
    },

    deleteServiceType(data) {
      this.$store.commit('serviceType/setFormData', data)
      this.$emit('delete')
    }
  }
}
</script>

<style></style>
