<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Providers ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search name, email or phone number"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <template>
        <v-select
          class="ml-10"
          hide-details
          dense
          item-text="label"
          item-value="value"
          v-model="defaultFilter"
          :items="filterBy"
          style="max-width: 200px"
          @change="filterProviders"
        />

        <v-btn
          depressed
          class="ml-2"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterProviders()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterProviders()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>

        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="provider-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-btn class="primary" elevation="2" small @click="showForm = true">
        <span>
          Add new Provider
        </span>
      </v-btn>

      <v-simple-table>
        <thead>
          <tr>
            <th width="20%" class="text-left pa-0">Name</th>
            <th width="20%" class="text-left">Email</th>
            <th width="20%" class="text-left">Address</th>
            <th width="15%" class="text-left">Phone number</th>
            <th width="15%" class="text-left">Date Added</th>
            <th width="10%" class="text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="provider in providers" :key="provider.id">
            <td>{{ provider.name }}</td>
            <td>{{ provider.email }}</td>
            <td>{{ provider.address }}</td>
            <td>{{ provider.phone_number }}</td>
            <td>{{ provider.addedDate }}</td>
            <td class="d-flex">
              <v-btn
                class="primary mr-1"
                small
                elevation="2"
                @click="
                  $router.push({
                    name: 'provider.details.info',
                    params: { id: provider.id }
                  })
                "
              >
                View
              </v-btn>
              <v-btn
                class="primary"
                small
                elevation="2"
                @click="initDelete(provider)"
              >
                Delete
              </v-btn>
            </td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(providers.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>

    <form-modal v-model="showForm" title="Add Provider" :max-width="600">
      <template #form>
        <provider-form :data="newProvider" ref="form" />
      </template>

      <template #submit-action>
        <v-btn text @click="cancelForm">Cancel</v-btn>
        <v-btn text :loading="newProvider.$busy" @click="addProvider"
          >Submit</v-btn
        >
      </template>
    </form-modal>

    <ConfirmModal
      v-model="showDeleteModal"
      title="Delete Provider"
      message="Are you sure you want to delete this provider?"
      @cancel="showDeleteModal = false"
      @confirm="confirmDelete"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown
} from '@mdi/js'

import ControlsMixin from '@/utils/mixins/Controls'
import infiniteScroll from 'vue-infinite-scroll'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import FormModal from '@/components/modals/FormModal.vue'
import debounce from 'lodash/debounce'
import ProviderForm from './components/ProviderForm.vue'
import Form from '@/utils/form'
import Api from '@/services/api'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ProvidersPage',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    FormModal,
    ProviderForm,
    ConfirmModal
  },
  directives: {
    infiniteScroll
  },
  data() {
    return {
      loading: false,
      showSearch: false,
      filter: {
        search: ''
      },
      showForm: false,
      sortOrder: true,
      defaultFilter: 'name',
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown
      },
      filterBy: [
        {
          value: 'name',
          label: 'Name'
        },
        {
          value: 'email',
          label: 'Email'
        },
        {
          value: 'phone_number',
          label: 'Phone number'
        },
        {
          value: 'address',
          label: 'Address'
        },
        {
          value: 'created_at',
          label: 'Added date'
        }
      ],
      newProvider: new Form({
        name: '',
        email: '',
        phone_number: '',
        address: '',
        logo: null
      }),
      selected: null,
      showDeleteModal: false
    }
  },
  computed: {
    ...mapState({
      providers: state => state.provider.list,
      listMeta: state => state.provider.listMeta
    }),
    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    }
  },
  created() {
    this.clearProviders()
    this.fetchProviders(1)
  },
  methods: {
    ...mapActions({
      getProviders: 'provider/getProviders'
    }),
    ...mapMutations({
      clearProviders: 'provider/clearProviderList'
    }),
    async fetchProviders(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getProviders(params)
      this.loading = false
    },
    search: debounce(function() {
      this.clearProviders()
      this.fetchProviders()
    }, 600),

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchProviders(this.listMeta.current_page + 1)
      }
    },
    filterProviders(filterValue) {
      this.clearProviders()
      this.fetchProviders(null, filterValue)
    },
    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
    addProvider() {
      this.newProvider.$busy = true

      const formData = new FormData()
      formData.append('name', this.newProvider.name)
      formData.append('email', this.newProvider.email)
      formData.append('phone_number', this.newProvider.phone_number)
      formData.append('address', this.newProvider.address)
      formData.append('description', this.newProvider.description)
      formData.append('logo', this.newProvider.logo)

      Api.post('/admin/providers', formData)
        .then(() => {
          this.newProvider.$busy = false
          this.showForm = false
          this.$refs.form.reset()
          this.fetchProviders()
        })
        .catch(error => {
          this.newProvider.$busy = false
          if (error.response.status === 422) {
            this.newProvider.$setErrors(error.response.data.errors)
          }
        })
    },
    initDelete(service) {
      this.selected = service
      this.showDeleteModal = true
    },
    confirmDelete() {
      Api.delete(`/admin/providers/${this.selected.id}`).then(() => {
        this.clearProviders()
        this.fetchProviders()
        this.selected = null
        this.showDeleteModal = false
      })
    },
    cancelForm() {
      this.showForm = false
      this.$refs.form.reset()
    }
  }
}
</script>
