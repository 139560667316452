<template>
  <div class="upload-photos" ref="uploadPhotos">
    <v-spacer></v-spacer>

    <v-row class="mt-2">
      <v-col
        v-for="(image, i) in imageData"
        :key="i"
        class="d-flex child-flex"
        cols="3"
      >
        <div class="upload-photos__image">
          <v-btn
            class="ma-2 upload-photos__close"
            fab
            x-small
            @click="removePhoto(image.id)"
          >
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
          <v-img
            :src="image.url"
            aspect-ratio="1"
            class="grey lighten-2"
            elevation="3"
          >
          </v-img>
        </div>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-btn
      class="primary mt-3 rounded"
      elevation="0"
      small
      single
      @click="$refs.filePicker.click()"
    >
      <span>
        <v-icon small>{{ icons.plus }}</v-icon> Add
      </span>
    </v-btn>
    <input
      ref="filePicker"
      class="d-none"
      type="file"
      multiple
      accept="image/*"
      @change="onFileChange"
    />
    <slot name="photo-error" />
  </div>
</template>

<script>
import { mdiPlus, mdiClose } from '@mdi/js'

export default {
  name: 'AddPhotos',

  data() {
    return {
      icons: {
        plus: mdiPlus,
        close: mdiClose
      },
      isUploading: false,
      imageData: []
    }
  },

  methods: {
    async onFileChange(value) {
      const files = [...value.target.files]

      // check if there are files
      if (!files.length) {
        return
      }

      for (const file of files) {
        // validate file type
        if (!file.type.match('image.*')) {
          this.$emit('error', {
            message: `${file.name} is not a valid image file`
          })
          return
        }
      }

      files.map((photo, index) =>
        this.imageData.push({
          id: index,
          file: photo,
          url: URL.createObjectURL(photo)
        })
      )

      this.$emit('images', this.imageData)
    },

    removePhoto(value) {
      const index = this.imageData.findIndex(image => image.id === value)
      this.imageData.splice(index, 1)

      this.$emit('images', this.imageData)
    },

    reset() {
      this.imageData = []
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-photos {
  max-width: 800px;

  &__image {
    position: relative;
  }

  &__close {
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 5;
  }
  .image-uploading {
    opacity: 0.5;
  }
}
</style>
