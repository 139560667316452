var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v("Bookings ("+_vm._s(_vm.listMeta.total)+")")]),_c('v-spacer'),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearch),expression:"showSearch"}],staticStyle:{"max-width":"200px"},attrs:{"align":"center"}},[_c('v-text-field',{ref:"searchField",staticClass:"search",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search boooking ID or customer name"},on:{"input":_vm.search},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),[_c('v-select',{staticClass:"ml-5",staticStyle:{"max-width":"120px"},attrs:{"hide-details":"","dense":"","item-text":"label","item-value":"value","items":[
          { value: '', label: 'All' },
          { value: 'pending', label: 'Pending' },
          { value: 'approved', label: 'Approved' },
          { value: 'rejected', label: 'Rejected' },
          { value: 'paid', label: 'Paid' }
        ]},on:{"change":_vm.filterProviders},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}}),_c('v-select',{staticClass:"ml-2",staticStyle:{"max-width":"125px"},attrs:{"hide-details":"","dense":"","item-text":"label","item-value":"value","items":_vm.filterBy},on:{"change":_vm.filterProviders},model:{value:(_vm.defaultFilter),callback:function ($$v) {_vm.defaultFilter=$$v},expression:"defaultFilter"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","color":_vm.sortOrder ? 'primary' : ''},on:{"click":function($event){_vm.sortOrder = true
          _vm.filterProviders()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.arrowUp))])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"depressed":"","color":!_vm.sortOrder ? 'primary' : ''},on:{"click":function($event){_vm.sortOrder = false
          _vm.filterProviders()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.arrowDown))])],1),_c('v-btn',{staticClass:"ml-5",attrs:{"icon":""},on:{"click":_vm.showSearchField}},[_c('img',{attrs:{"src":require("@/assets/icons/search.svg"),"alt":"","height":"17.7px"}})])]],2),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"mt-12 mx-4",attrs:{"id":"provider-list","infinite-scroll-disabled":_vm.loading,"infinite-scroll-distance":"100"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left pa-0",attrs:{"width":"20%"}},[_vm._v("ID")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v("Customer")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v("Event")]),_c('th',{staticClass:"text-left",attrs:{"width":"15%"}},[_vm._v("Provider")]),_c('th',{staticClass:"text-left",attrs:{"width":"10%"}},[_vm._v("Status")]),_c('th',{staticClass:"text-left",attrs:{"width":"15%"}},[_vm._v("Date Added")])])]),_c('tbody',[_vm._l((_vm.bookings),function(booking){return _c('tr',{key:booking.id,staticClass:"clickable",on:{"click":function($event){return _vm.$router.push({
              name: 'bookings.details',
              params: { id: booking.booking_id }
            })}}},[_c('td',[(booking.is_new)?_c('v-badge',{attrs:{"color":"green","message":"new","content":"new","top":""}},[_vm._v(" "+_vm._s(booking.booking_id)+" ")]):_c('span',[_vm._v(_vm._s(booking.booking_id))])],1),_c('td',[_vm._v(_vm._s(booking.booking_details.full_name))]),_c('td',[_vm._v(_vm._s(booking.listing.event.name))]),_c('td',[_vm._v(_vm._s(booking.listing.provider.name))]),_c('td',{staticClass:"text-capitalize"},[_vm._v(_vm._s(booking.status))]),_c('td',[_vm._v(_vm._s(booking.addedDate))])])}),(_vm.loading)?_vm._l((10),function(index){return _c('tr',{key:index + '-skeleton'},[_c('td',{staticClass:"py-3 pl-0",attrs:{"width":"5%"}},[_c('v-skeleton-loader',{staticClass:"avatar-loading--36",attrs:{"type":"avatar","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1),_c('td',{staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1)])}):_vm._e()],2)]),(!(_vm.bookings.length > 0) && !_vm.loading)?_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('p',[_vm._v("No match found.")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }