<template>
  <div class="filter-container">
    <v-row v-show="showSearch" align="center" style="max-width: 300px">
      <v-text-field
        class="search"
        ref="searchField"
        flat
        hide-details
        solo
        rounded
        clearable
        background-color="grey lighten-3"
        label="Search name, email or phone number"
        v-model="filter.search"
        @input="$emit('search')"
      ></v-text-field>
    </v-row>
    <template>
      <v-select
        class="ml-10"
        hide-details
        dense
        item-text="label"
        item-value="value"
        v-model="defaultFilter"
        :items="filterBy"
        style="max-width: 200px"
        @change="filterEvent"
      />

      <v-btn
        depressed
        class="ml-2"
        :color="sortOrder ? 'primary' : ''"
        @click="
          sortOrder = true
          filterEvent()
        "
      >
        <v-icon>{{ icons.arrowUp }}</v-icon>
      </v-btn>
      <v-btn
        depressed
        class="ml-2"
        :color="!sortOrder ? 'primary' : ''"
        @click="
          sortOrder = false
          filterEvent()
        "
      >
        <v-icon>{{ icons.arrowDown }}</v-icon>
      </v-btn>

      <v-btn class="ml-5" icon @click="showSearchField">
        <img src="@/assets/icons/search.svg" alt height="17.7px" />
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mdiArrowUp, mdiArrowDown } from '@mdi/js'
export default {
  names: 'Filter',

  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  watch: {
    filter() {
      this.$emit('input', this.filter.search)
    }
  },

  data() {
    return {
      showSearch: false,
      icons: {
        arrowDown: mdiArrowDown,
        arrowUp: mdiArrowUp
      },
      defaultFilter: 'name',
      filterBy: [
        {
          value: 'name',
          label: 'Name'
        },
        {
          value: 'created_at',
          label: 'Added date'
        }
      ],
      sortOrder: true
    }
  },

  methods: {
    filterEvent() {
      this.$emit(
        'filter-event',
        this.sortOrder ? `${this.defaultFilter}` : `-${this.defaultFilter}`
      )
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-container {
  display: contents;
}
</style>
